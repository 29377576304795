import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Grid
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { api, getProfile } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { selectOpportunities, updateOpportunityLocally, fetchOpportunities, selectOpportunitiesLoading, selectOpportunitiesError } from "../reduxstore/opportunitiesSlice";

const ConfidenceScore = ({ score }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, marginTop: "15%" }}>
      {[1, 2, 3, 4, 5].map((index) => (
        <Box
          key={index}
          sx={{
            width: 13,
            height: 10,
            backgroundColor: index <= score ? 'success.main' : 'grey.200',
            border: 1,
            borderColor: 'grey.300'
          }}
        />
      ))}
    </Box>
  );
};

const LoadingSkeleton = () => (
  <Box
    sx={{
      height: 'calc(100vh - 200px)',
      width: '100%',
      backgroundColor: 'background.paper',
      borderRadius: 2,
      boxShadow: 1,
      p: 2
    }}
  >
    {[...Array(5)].map((_, index) => (
      <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={300} height={40} />
        <Skeleton variant="rectangular" width={200} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={150} height={40} />
        <Skeleton variant="rectangular" width={50} height={40} />
      </Box>
    ))}
  </Box>
);

const Opportunities = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunities = useSelector(selectOpportunities);
  const loading = useSelector(selectOpportunitiesLoading);
  const error = useSelector(selectOpportunitiesError);
  const dispatch = useDispatch();
  const [showShortlisted, setShowShortlisted] = useState(false);
  const user = getProfile();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: Number(searchParams.get('pageSize')) || 10,
    page: Number(searchParams.get('page')) || 0,
  });

  useEffect(() => {
    setSearchParams({
      page: paginationModel.page.toString(),
      pageSize: paginationModel.pageSize.toString()
    });
  }, [paginationModel, setSearchParams]);

  const getGridHeight = () => {
    // Each row is approximately 52px high (default MUI DataGrid row height)
    // Header is approximately 56px
    // Pagination controls are approximately 52px
    const rowHeight = 52;
    const headerHeight = 56;
    const paginationHeight = 52;

    if (paginationModel.pageSize <= 5) {
      // For 5 or fewer rows, calculate exact height needed
      return `${(rowHeight * paginationModel.pageSize) + headerHeight + paginationHeight}px`;
    } else {
      // For more rows, set a fixed height with scroll
      return 'calc(100vh - 150px)';
    }
  };


  useEffect(() => {
    if (opportunities.length === 0) {
      dispatch(fetchOpportunities());
    }
  }, [dispatch]);

  const handleRowClick = async (params) => {
    try {
      const isViewed = params.row.viewedBy?.some(view => view.userId === user._id);
      // dont wait for this to complete
      if (!isViewed) {
        api.put(`/capture/opportunities/${params.row.rfx_id}`, {
          viewed: true
        });

        // Update local state with new viewedBy entry
        const now = new Date().toISOString();
        dispatch(updateOpportunityLocally({
          rfx_id: params.row.rfx_id,
          changes: {
            viewedBy: [
              ...(params.row.viewedBy || []),
              {
                userId: user._id,
                viewedAt: now,
                lastViewedAt: now,
                viewCount: 1
              }
            ]
          }
        }));
      }

      navigate(`/opportunities/${params.row.rfx_id}?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`);
    } catch (err) {
      console.error("Error updating viewed status:", err);
    }
  };

  const handleFavoriteToggle = async (rfx_id, currentFavoriteState) => {
    try {
      const newFavoriteState = !currentFavoriteState;

      // Optimistically update UI
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          favoritedBy: newFavoriteState
            ? [...(opportunities.find(o => o.rfx_id === rfx_id)?.favoritedBy || []),
            { userId: user._id, favoritedAt: new Date().toISOString() }]
            : (opportunities.find(o => o.rfx_id === rfx_id)?.favoritedBy || [])
              .filter(f => f.userId !== user._id)
        }
      }));

      await api.put(`/capture/opportunities/${rfx_id}`, {
        isFavorite: newFavoriteState
      });
    } catch (err) {
      console.error("Error updating favorite status:", err);
      // Revert on error
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          favoritedBy: opportunities.find(o => o.rfx_id === rfx_id)?.favoritedBy || []
        }
      }));
    }
  };

  const columns = [
    {
      field: 'matchScore',
      headerName: 'Match Relevance',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return <ConfidenceScore score={params.row.matchScore} />
      },
      sortComparator: (v1, v2) => v1 - v2
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 350,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'publication_date',
      headerName: 'Published Date',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    {
      field: 'deadline',
      headerName: 'Due Date',
      width: 120,
      valueFormatter: (params) => {
        return new Date(params).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
    },
    {
      field: 'favorite',
      headerName: 'Shortlist',
      width: 80,
      sortable: false,
      renderCell: (params) => {
        const isFavorited = params.row.favoritedBy?.some(
          fav => fav.userId === user._id
        );
        return (
          <Box display="flex" justifyContent="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleFavoriteToggle(params.row.rfx_id, isFavorited);
              }}
              sx={{
                color: isFavorited ? 'error.main' : 'action.disabled',
                '&:hover': {
                  color: 'error.main'
                }
              }}
            >
              {isFavorited ?
                <FavoriteIcon sx={{ fontSize: "1rem", marginTop: "50%" }} /> :
                <FavoriteBorderIcon sx={{ fontSize: "1rem", marginTop: "50%" }} />
              }
            </IconButton>
          </Box>
        )
      }
    }
  ];

  if (loading) {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh"
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ margin: "1.5rem 2rem" }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const filteredOpportunities = opportunities
    .filter(opp => opp.scoring.score > 2)
    .filter(opp => !showShortlisted ||
      opp.favoritedBy?.some(fav => fav.userId === user._id)
    );

  const rowData = filteredOpportunities.map(opp => ({
    ...opp,
    matchScore: opp.scoring.score,
    location: `${opp.state}, ${opp.country}`
  }));

  const getRowClassName = (params) => {
    const isViewed = params.row.viewedBy?.some(
      view => view.userId === user._id
    );
    return isViewed ? 'viewed-row' : '';
  };

  const handleFilterChange = (event, newShowShortlisted) => {
    setShowShortlisted(newShowShortlisted);
  };

  return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 4
        }}
      >
        <Typography variant="pageHeading">Opportunities</Typography>

      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <ToggleButtonGroup
          value={showShortlisted}
          exclusive
          onChange={handleFilterChange}
          aria-label="shortlist filter"
          size="small"
        >
          <ToggleButton
            value={true}
            aria-label="show shortlisted"
            sx={{
              px: 1,
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                }
              }
            }}
          >
            <FavoriteIcon sx={{ fontSize: '0.875rem', mr: 1 }} />
            Shortlisted ({filteredOpportunities.filter(opp =>
              opp.favoritedBy?.some(fav => fav.userId === user._id)
            ).length})
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : loading ? (
        <LoadingSkeleton />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                height: getGridHeight(),
                width: '100%',
                '& .MuiDataGrid-root': {
                  border: 'none',
                  backgroundColor: 'background.paper',
                  borderRadius: 2,
                  boxShadow: 1
                },
                '& .MuiDataGrid-cell': {
                  fontSize: '0.875rem'
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'background.paper',
                  borderBottom: 1,
                  borderColor: 'divider'
                },
                '& .viewed-row': {
                  backgroundColor: 'rgba(234, 242, 244, 0.8)',
                  '&:hover': {
                    backgroundColor: 'rgba(234, 242, 244, 0.8)',
                  }
                }
              }}
            >
              <DataGrid
                rows={rowData}
                columns={columns}
                getRowId={(row) => row.rfx_id}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[5, 10, 25, 50]}
                disableRowSelectionOnClick
                onRowClick={handleRowClick}
                rowCount={opportunities.length}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'publication_date', sort: 'asc' }],
                  },
                }}
                getRowClassName={getRowClassName}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Opportunities;