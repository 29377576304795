import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  Link,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import { api } from "../store";

const OpportunityDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [opportunity, setOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOpportunityDetails();
  }, [id]);

  const fetchOpportunityDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/capture/getOpportunity/${id}`);
      setOpportunity(response.data.opportunity);
      setError(null);
    } catch (err) {
      setError("Failed to fetch opportunity details");
      console.error("Error fetching opportunity details:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    const page = searchParams.get('page') || '0';
    const pageSize = searchParams.get('pageSize') || '10';
    navigate(`/rfx-opportunities?page=${page}&pageSize=${pageSize}`);
  };

  const handleDownload = () => {
    // Implement download functionality
    window.open(opportunity.document_link, '_blank');
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !opportunity) {
    return (
      <Box sx={{ margin: "1.5rem 2rem" }}>
        <Alert severity={error ? "error" : "warning"}>
          {error || "Opportunity not found"}
        </Alert>
      </Box>
    );
  }

  const formattedDate = new Date(opportunity.deadline).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="pageHeading" component="span">
            {opportunity.title}
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleDownload}
          sx={{ ml: 2 }}
        >
          Download RFX Files
        </Button>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3} >
        {/* Left Column - Overview */}
        <Grid item xs={12} md={7} sx={{ mb: 2 }}>
          <Paper
            elevation={1}
            sx={{
              p: 2,
              borderRadius: 2,
              height: '100%'
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                Due Date: {formattedDate}
              </Typography>
              {opportunity.question_answer_date && opportunity.question_answer_date != "0000-00-00" && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Question Answer Date: {new Date(opportunity.question_answer_date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Typography>
              )}
              {opportunity.preproposal_date && opportunity.preproposal_date != "0000-00-00" && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Preproposal Date: {new Date(opportunity.preproposal_date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Typography>
              )}
              {opportunity.eligibility && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Eligibility: {opportunity.eligibility}
                </Typography>
              )}
              {opportunity.country && opportunity.state && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Location: {opportunity.state}, {opportunity.country}
                </Typography>
              )}
            </Box>

            <Divider sx={{ my: 3 }} />

            {opportunity.overview ? Object.entries(opportunity.overview).map(([key, value]) => (
              <Box
                key={key}
                sx={{
                  mb: 2  // Add margin bottom for spacing between sections
                }}
              >
                <Typography
                  variant="secondaryText"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    mb: 1,  // Reduced margin bottom
                    textTransform: 'capitalize'
                  }}
                >
                  {key.split('_').join(' ')}
                </Typography>
                {Array.isArray(value) ? (
                  <List dense sx={{ pl: 2 }}>  {/* Added left padding for better hierarchy */}
                    {value.map((item, index) => (
                      <ListItem key={index} sx={{ px: 0, mt: 0 }}>  {/* Removed default padding */}
                        <ListItemText
                          primary={`${index + 1}. ${item}`}
                          sx={{
                            '& .MuiListItemText-primary': {
                              fontSize: '0.875rem',
                              lineHeight: 1.5,
                              color: 'text.primary'  // Ensure consistent text color
                            }
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    variant="primaryText"
                    sx={{
                      lineHeight: 1.6,
                      pl: 2  // Added left padding to align with lists
                    }}
                  >
                    {value}
                  </Typography>
                )}
              </Box>
            )) : opportunity.scope && <Typography
              variant="primaryText"
              sx={{
                lineHeight: 1.6,
                pl: 2  // Added left padding to align with lists
              }}
            >
              {opportunity.scope}
            </Typography>}
          </Paper>
        </Grid>

        {/* Right Column - Scoring */}
        <Grid item xs={12} md={5} sx={{ mb: 2 }}>
          <Paper
            elevation={1}
            sx={{
              p: 4,
              borderRadius: 2,
              height: '100%'
            }}
          >
            <Typography variant="secondaryText"
              sx={{
                fontWeight: "bold",
                color: "black",
                mb: 2,
                textTransform: 'capitalize'
              }} gutterBottom>
              Opportunity Analysis
            </Typography>

            <Box sx={{ mb: 4 }}>
              <Typography variant="secondaryText" sx={{ fontWeight: 'bold', mb: 1 }}>
                Match Score
              </Typography>
              <Typography variant="h4" color="primary.main">
                {opportunity.scoring.score}/5
              </Typography>
            </Box>

            {opportunity.analysis_status === "partial" && (
              <Typography sx={{ mb: 3, lineHeight: 1.6, fontStyle: "italic", fontSize: "0.875rem", color: "grey" }}>
                Partial analysis available. Some RFx files could not be retrieved for this opportunity.
              </Typography>
            )}

            <Divider sx={{ my: 3 }} />

            {opportunity.analysis_status === "failed" && (
              <Typography variant="primaryText" sx={{ mb: 3, lineHeight: 1.6 }}>
                Scoring data is unavailable. We were unable to retrieve the RFx files for this opportunity.
              </Typography>
            )}


            {opportunity.scoring.scoring_reasoning && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="secondaryText"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    mb: 2,
                    textTransform: 'capitalize'
                  }}>
                  Analysis
                </Typography>
                <Typography variant="primaryText" sx={{ mb: 3, lineHeight: 1.6 }}>
                  {opportunity.scoring.scoring_reasoning}
                </Typography>
              </Box>
            )}

            {opportunity.scoring.pros && opportunity.scoring.pros.length > 0 && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="secondaryText"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    mb: 2,
                    textTransform: 'capitalize'
                  }}>
                  Positives
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {opportunity.scoring.pros.map((positive, index) => (
                    <Chip
                      key={index}
                      label={positive}
                      color="success"
                      variant="outlined"
                      sx={{
                        mb: 1,
                        height: 'auto',
                        '& .MuiChip-label': {
                          whiteSpace: 'normal',
                          display: 'block',
                          padding: '8px 12px',
                          lineHeight: 1.4
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {opportunity.scoring.cons && opportunity.scoring.cons.length > 0 && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="secondaryText"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    mb: 2,
                    textTransform: 'capitalize'
                  }}>
                  Negatives
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {opportunity.scoring.cons.map((negative, index) => (
                    <Chip
                      key={index}
                      label={negative}
                      color="error"
                      variant="outlined"
                      sx={{
                        mb: 1,
                        height: 'auto',
                        '& .MuiChip-label': {
                          whiteSpace: 'normal',
                          display: 'block',
                          padding: '8px 12px',
                          lineHeight: 1.4
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {opportunity.scoring.red_flags && opportunity.scoring.red_flags.length > 0 && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="secondaryText"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    mb: 2,
                    textTransform: 'capitalize'
                  }}>
                  Red Flags
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {opportunity.scoring.red_flags.map((flag, index) => (
                    <Chip
                      key={index}
                      label={flag}
                      color="error"
                      variant="filled"
                      sx={{
                        mb: 1,
                        height: 'auto',
                        '& .MuiChip-label': {
                          whiteSpace: 'normal',
                          display: 'block',
                          padding: '8px 12px',
                          lineHeight: 1.4
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OpportunityDetail;