import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Button,
  Modal,
  Paper,
  Tooltip,
  Badge,
  Menu,
  MenuItem,
  ClickAwayListener,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useSelector, useDispatch } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';

import {
  addItem,
  reorderItems,
  deleteItemAndUpdate,
  setItems,
  updateItem,
  addItemAndUpdate,
} from "../../reduxstore/itemsSlice";
import {
  addItemStatus,
  updateItemStatus,
} from "../../reduxstore/itemsStatusSlice";
import { api_external } from "../../store";
import env from "../../config";
import { v4 as uuidv4 } from "uuid";
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

function FacebookCircularProgress(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.grey[200],
          ...theme.applyStyles("dark", {
            color: theme.palette.grey[800],
          }),
        })}
        size={18}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: "#55828b",
          animationDuration: "550ms",
          position: "absolute",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            color: "#308fe8",
          }),
        })}
        size={18}
        thickness={5}
        {...props}
      />
    </Box>
  );
}

const EditableItem = React.memo(
  ({
    parentId,
    item,
    index,
    editMode,
    onItemClick,
    onDeleteItem,
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnd,
    isDraggedOver,
    isLoading,
    isError,
    isSelected,
    todoCount,
    onRename,
    isRenaming,
    newTitle,
    onRenameChange,
    onRenameSubmit,
    onRenameCancel,
  }) => {
    const itemRef = useRef(null);
    const renameInputRef = useRef(null);
    const [contextMenu, setContextMenu] = useState(null);

    useEffect(() => {
      if (isRenaming && renameInputRef.current) {
        renameInputRef.current.focus();
      }
    }, [isRenaming]);

    const handleContextMenu = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setContextMenu(
        contextMenu === null
          ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
          : null,
      );
    };

    const handleCloseContextMenu = () => {
      setContextMenu(null);
    };

    const handleRenameClick = (event) => {
      event.stopPropagation();
      onRename(item);
      handleCloseContextMenu();
    };

    const handleDeleteClick = (event) => {
      event.stopPropagation();
      onDeleteItem(item);
      handleCloseContextMenu();
    };

    const ItemIcon = ({ parentId, type }) => {
      const iconStyle = { color: "#666666", fontSize: "0.875rem" };
      if (parentId) {
        // Parent ID is not empty
        if (type === "Form") {
          return <AttachFileRoundedIcon sx={iconStyle} />;
        } else {
          // Assuming "Free Text" type when not "Form"
          // return <TextSnippetOutlinedIcon sx={iconStyle} />;
          return <SubjectRoundedIcon sx={iconStyle} />;
        }
      } else {
        // Parent ID is empty
        if (type === "Form") {
          return <FilePresentOutlinedIcon sx={iconStyle} />;
        } else {
          // Assuming "Free Text" type when not "Form"
          return <InsertDriveFileOutlinedIcon sx={iconStyle} />;
        }
      }
    };

    return (
      <ListItem
        ref={itemRef}
        draggable={editMode}
        onDragStart={(e) => onDragStart(e, index)}
        onDragOver={(e) => onDragOver(e, index)}
        onDrop={(e) => onDrop(e, index)}
        onDragEnd={onDragEnd}
        onClick={() => !isRenaming && onItemClick(item, parentId)}
        onContextMenu={handleContextMenu}
        sx={{
          borderRadius: "8px",
          borderTopLeftRadius: isDraggedOver ? "0" : "8px",
          borderTopRightRadius: isDraggedOver ? "0" : "8px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          backgroundColor: isSelected ? "rgba(0, 0, 0, 0.06)" : "none",
          my: 0.5,
          cursor: editMode ? "move" : "pointer",
          borderTop: isDraggedOver ? "3px solid #719fa8" : "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "8px",
        }}
        title={item.title}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.7rem",
            alignItems: "center",
            flexGrow: 1,
            minWidth: 0,
          }}
        >

          {editMode && (
            <DragIndicatorIcon
              fontSize="small"
              sx={{ minWidth: "8px", paddingTop: "0px", paddingBottom: "0px" }}
            />
          )}
          <ItemIcon parentId={parentId} type={item.type} />
          {isRenaming ? (
            <ClickAwayListener onClickAway={onRenameSubmit}>
              <TextField
                value={newTitle}
                onChange={(e) => onRenameChange(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onRenameSubmit();
                  } else if (e.key === 'Escape') {
                    onRenameCancel();
                  }
                }}
                inputRef={renameInputRef}
                fullWidth
                size="small"
                sx={{ mr: 1 }}
              />
            </ClickAwayListener>
          ) : (
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                sx: {
                  fontSize: "0.875rem",
                  lineHeight: 1.2,
                  color: "#666666",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  paddingRight: "0.5rem",
                },
              }}
              sx={{
                cursor: "pointer",
                flexGrow: 1,
                minWidth: 0,
              }}
            />
          )}
          {!editMode && !isLoading && !isError && todoCount > 0 && (
            <Badge
              title="TODOs"
              badgeContent={todoCount}
              sx={{
                "& .MuiBadge-badge": {
                  color: "#55828b",
                  border: "0.1rem solid #55828b",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& .css-ywgamk-MuiBadge-badge": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "0.1rem",
                },
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
          {isLoading && <FacebookCircularProgress />}
          {isError && !isLoading && (
            <ErrorRoundedIcon
              sx={{ marginRight: "2px", color: "red", fontSize: "16px" }}
            />
          )}
        </Box>
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem sx={{ px: 4, fontSize: "0.875rem" }} onClick={handleDeleteClick}>Delete</MenuItem>
          <MenuItem sx={{ px: 4, fontSize: "0.875rem" }} onClick={handleRenameClick}>Rename</MenuItem>
        </Menu>
      </ListItem>
    );
  }
);

const DraftLeftPanelComponent = ({
  onItemClick,
  projectId,
  archiveIds,
  refreshItems,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const listRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newSectionDescription, setNewSectionDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const items = useSelector((state) => state.items);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);
  const [subsections, setSubsections] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [renamingItem, setRenamingItem] = useState(null);
  const [newItemTitle, setNewItemTitle] = useState("");
  const [isFormSection, setIsFormSection] = useState(false);

  const handleRename = (item) => {
    setRenamingItem(item);
    setNewItemTitle(item.title);
  };

  useEffect(() => {
    //get currentDocument.uuid from items and set currentDocument
    if (currentDocument) {
      const currentDoc = items.find((item) => item.uuid === currentDocument.uuid);
      if (currentDoc) {
        setCurrentDocument(currentDocument);
      }
    }
  }, [items, currentDocument]);

  useEffect(() => {
    if (selectedItem && selectedItem.parent_id) {
      const currentDoc = items.find((item) => item.uuid === selectedItem.parent_id);
      setCurrentDocument(currentDoc);
    }
  }, [selectedItem]);

  const handleRenameChange = (newTitle) => {
    setNewItemTitle(newTitle);
  };

  const handleRenameSubmit = async () => {
    if (renamingItem && newItemTitle.trim() !== "") {
      const updatedItem = { ...renamingItem, title: newItemTitle.trim() };
      try {
        const resultAction = await dispatch(updateItem({
          uuid: renamingItem.uuid,
          key: 'title',
          value: newItemTitle.trim(),
          projectId
        }));
        if (updateItem.fulfilled.match(resultAction)) {
          refreshItems();
        } else {
          // If the action was rejected, we can access the error
          console.error("Failed to update item:", resultAction.error);
        }

      } catch (error) {
        console.error("Error renaming item:", error);
        // Implement error handling (e.g., show an error message to the user)
      }
    }
    setRenamingItem(null);
    setNewItemTitle("");
  };

  const handleRenameCancel = () => {
    setRenamingItem(null);
    setNewItemTitle("");
  };

  const handleDocumentClick = (doc) => {
    if (doc.sections && doc.sections.length > 0) {
      setCurrentDocument(doc);
      const firstSection = doc.sections.find((section) => !section.tag);
      onItemClick(firstSection, doc.uuid);
    } else {
      onItemClick(doc);
    }
  };

  const handleBackClick = () => {
    setCurrentDocument(null);
    setEditMode(false);
  };

  const renderHeader = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        // mx: 2,
        // paddingTop: 2,
        paddingBottom: 4,
        borderBottom: "1px solid #ddd",
        height: "2rem",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: "80%" }}>
        {currentDocument && (
          <IconButton
            onClick={handleBackClick}
            size="small"
            sx={{ mr: 1, p: 0 }}
          >
            <ArrowBackIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        )}
        <Typography
          variant="sectionHeading"
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 1,
            flexShrink: 1,
            minWidth: 0, // This allows the text to shrink below its content size
          }}
        >
          {currentDocument ? currentDocument.title : "Bid Documents"}
        </Typography>
      </Box>
      {currentDocument && (
        <Tooltip title="Edit document structure" arrow>
          <IconButton
            onClick={() => setEditMode(!editMode)}
            size="small"
            sx={{ padding: 0 }}
          >
            {editMode ? (
              <CancelIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const renderContent = () => {
    if (currentDocument) {
      const currentDocumentSections = items.find((item) => item.uuid === currentDocument.uuid)?.sections || [];
      return (
        <List sx={{ flexGrow: 1, overflowY: "auto" }}>
          {currentDocumentSections.map((section, index) => {
            // Do not display the section if it has a tag ex. table_of_contents
            if (section.tag && section.tag != null)
              return;
            const status = itemsStatus.find((status) => status.uuid === section.uuid) || {};
            return (
              <EditableItem
                parentId={currentDocument.uuid}
                key={section.uuid}
                item={section}
                index={index}
                editMode={editMode}
                onItemClick={onItemClick}
                onDeleteItem={handleDeleteItem}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragEnd={onDragEnd}
                isDraggedOver={dragOverIndex === index}
                isLoading={status.isGenerating || false}
                isError={status.isError || false}
                isSelected={section.uuid === selectedItem?.uuid}
                todoCount={status.todoCount}
                onRename={handleRename}
                isRenaming={renamingItem?.uuid === section.uuid}
                newTitle={newItemTitle}
                onRenameChange={handleRenameChange}
                onRenameSubmit={handleRenameSubmit}
                onRenameCancel={handleRenameCancel}
              />
            );
          })}
        </List>
      );
    }

    return (
      <List ref={listRef} sx={{ flexGrow: 1, overflowY: "auto" }}>
        {items.map((doc, index) => {
          const status = itemsStatus.find((status) => status.uuid === doc.uuid) || {};
          return (
            <Box key={doc.uuid}>
              <EditableItem
                parentId={null}
                item={doc}
                index={index}
                editMode={editMode}
                onItemClick={() => handleDocumentClick(doc)}
                onDeleteItem={handleDeleteItem}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragEnd={onDragEnd}
                isDraggedOver={dragOverIndex === index}
                isLoading={status.isGenerating || false}
                isError={status.isError || false}
                isSelected={doc.uuid === selectedItem?.uuid}
                todoCount={status.todoCount}
                onRename={handleRename}
                isRenaming={renamingItem?.uuid === doc.uuid}
                newTitle={newItemTitle}
                onRenameChange={handleRenameChange}
                onRenameSubmit={handleRenameSubmit}
                onRenameCancel={handleRenameCancel}
              />
            </Box>
          );
        })}
      </List>
    );
  };

  const handleDeleteItem = useCallback(
    async (itemToDelete) => {
      const originalItems = [...items];
      let updatedItems = items.map(item => {
        if (item.type === 'Free Text') {
          const updatedSections = item.sections.filter(section => {
            return section.uuid !== itemToDelete.uuid;
          });
          return {
            ...item,
            sections: updatedSections
          };
        }
        return item;
      }).filter(item => {
        if (itemToDelete.uuid) {
          return item.uuid !== itemToDelete.uuid;
        }
        return true;
      });
      // Optimistically update the UI
      dispatch(setItems(updatedItems));

      try {
        await dispatch(
          deleteItemAndUpdate({ itemToDelete, projectId })
        ).unwrap();
      } catch (error) {
        console.error("Error deleting item:", error);
        // Revert the change if the operation fails
        dispatch(setItems(originalItems));
        setSnackbarMessage("Failed to delete item. Please try again.");
        setSnackbarOpen(true);
      }
    },
    [items, dispatch, projectId]
  );

  const handleAddItem = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewSectionTitle("");
    setNewSectionDescription("");
  };

  // Ensure that editMode is not changed during drag & drop
  const onDragStart = useCallback(
    (e, index) => {
      if (currentDocument && currentDocument.type === 'Free Text') {
        setDraggedItem(currentDocument.sections[index]);
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/plain", index);
      }
    },
    [currentDocument]
  );

  const onDragOver = useCallback((e, index) => {
    e.preventDefault();
    if (currentDocument && currentDocument.type === 'Free Text') {
      setDragOverIndex(index);
    }
  }, [currentDocument]);

  const onDragEnd = useCallback(() => {
    setDraggedItem(null);
    setDragOverIndex(null);
  }, []);

  const onDrop = useCallback(
    async (e, droppedIndex) => {
      e.preventDefault();
      if (currentDocument && currentDocument.type === 'Free Text' && Array.isArray(currentDocument.sections)) {
        const draggedIndex = Number(e.dataTransfer.getData("text/plain"));

        if (draggedIndex === droppedIndex) return;

        const updatedSections = [...currentDocument.sections];
        const [reorderedItem] = updatedSections.splice(draggedIndex, 1);
        updatedSections.splice(droppedIndex, 0, reorderedItem);

        const updatedDocument = {
          ...currentDocument,
          sections: updatedSections
        };

        const updatedItems = items.map(item =>
          item.uuid === currentDocument.uuid ? updatedDocument : item
        );

        try {
          // Update the backend
          await dispatch(reorderItems({ newOrder: updatedItems, projectId })).unwrap();

          // Update the Redux store
          dispatch(setItems(updatedItems));

          // Update the local state
          setCurrentDocument(updatedDocument);
        } catch (error) {
          console.error("Error updating items after drag and drop:", error);
          // Optionally, revert the drag and drop if the backend update fails
        }
      }
    },
    [currentDocument, items, dispatch, projectId, reorderItems]
  );

  const handleSubsectionChange = (index, field, value) => {
    const updatedSubsections = [...subsections];
    updatedSubsections[index][field] = value;
    setSubsections(updatedSubsections);
  };

  const addSubsection = () => {
    setSubsections([...subsections, { title: "", description: "" }]);
  };

  const removeSubsection = (index) => {
    const updatedSubsections = [...subsections];
    updatedSubsections.splice(index, 1);
    setSubsections(updatedSubsections);
  };

  const handleGenerateNewSection = async () => {
    // Check for empty fields
    if (!newSectionTitle.trim() || !newSectionDescription.trim()) {
      setSnackbarMessage("Section title and description are required.");
      setSnackbarOpen(true);
      return;
    }

    // Check if any subsection has empty fields
    if (!isFormSection) {
      const hasEmptySubsection = subsections.some(
        (subsection) => !subsection.title.trim() || !subsection.description.trim()
      );
      if (hasEmptySubsection) {
        setSnackbarMessage("All subsection fields must be filled.");
        setSnackbarOpen(true);
        return;
      }
    }


    handleCloseModal();
    setEditMode(false);
    const id = uuidv4();

    const newItem = {
      uuid: id,
      title: newSectionTitle,
      description: newSectionDescription,
    };


    if (isFormSection) {
      newItem.type = "Form";
      newItem.sections = [];
      // Optimistically add the new item to its oarent item and update in backend
      await dispatch(addItemAndUpdate({
        item: newItem,
        parentUuid: currentDocument.uuid, // optional, omit for top-level items
        projectId: projectId
      }));
    } else {
      newItem.type = "Free Text";
      // Optimistically add the new item
      dispatch(addItem({ item: newItem, parentUuid: currentDocument.uuid }));
      dispatch(addItemStatus({ uuid: id, isGenerating: true }));

      // Add Free Text to subsections
      const newSubsections = subsections.map((subsection) => ({
        ...subsection,
        type: "Free Text",
      }));

      try {
        const requestData = {
          document_id: currentDocument.uuid,
          project_id: projectId,
          section_id: id,
          section_title: newSectionTitle,
          section_desc: newSectionDescription,
          archive_ids: archiveIds,
          subsections: newSubsections,
        };

        await api_external.post(
          `${env.salesPubAPI}/generate-rfx-section-response`,
          requestData
        );
        dispatch(updateItemStatus({ uuid: id, isGenerating: false }));
        refreshItems(id, true);
      }
      catch (error) {
        console.error("Error generating section:", error);
        dispatch(setItems(items.filter((item) => item.uuid !== id)));
        setSnackbarMessage("Failed to generate section. Please try again.");
        setSnackbarOpen(true);
      }
    }

  };

  const handleCheckboxChange = (event) => {
    setIsFormSection(event.target.checked);
    if (event.target.checked) {
      setSubsections([]); // Clear subsections when switching to form
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderHeader()}
      {renderContent()}
      {editMode && currentDocument && (
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddItem}
            fullWidth
            sx={{ mt: 2, textTransform: "none" }}
          >
            Add New Section
          </Button>
        </Box>
      )}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-section-modal"
        aria-describedby="modal-to-add-new-section"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxHeight: "80vh",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: "auto",
          }}
        >
          <Typography variant="primaryHeading" sx={{ color: "black" }}>
            Add New Section
          </Typography>
          {snackbarOpen && (
            <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 1 }}>
              <Typography
                variant="body2"
                color="error"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ErrorIcon sx={{ mr: 1 }} />
                {snackbarMessage}
              </Typography>
            </Box>
          )}
          <TextField
            label="Section title"
            value={newSectionTitle}
            onChange={(e) => setNewSectionTitle(e.target.value)}
            fullWidth
            required
            variant="outlined"
            margin="dense"
            inputProps={{ maxLength: 50 }} // Limit the title to 100 characters
          />
          <TextField
            label="Section description"
            value={newSectionDescription}
            onChange={(e) => setNewSectionDescription(e.target.value)}
            fullWidth
            required
            variant="outlined"
            margin="dense"
            multiline
            minRows={3}
            inputProps={{ maxLength: 150 }} // Limit the description to 500 characters
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFormSection}
                  onChange={handleCheckboxChange}
                  name="sectionType"
                />
              }
              sx={{ fontSize: '0.875rem' }}
              label={
                <Typography sx={{ fontSize: '0.875rem' }}>
                  Form section
                </Typography>
              }
            />
          </Box>
          {!isFormSection && (
            <Box> <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
                mb: 0.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Typography variant="subtitle1">Subsections</Typography>
                <Tooltip title="You can add up to 4 subsections to your new section.">
                  <ErrorOutlineIcon sx={{ color: "grey", fontSize: "1rem" }} />
                </Tooltip>
              </Box>
              {subsections.length < 4 && (
                <IconButton onClick={addSubsection} size="small">
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
            </Box>
              {subsections.map((subsection, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: 1, mt: 0.5 }}
                >
                  <TextField
                    label="Title"
                    value={subsection.title}
                    onChange={(e) =>
                      handleSubsectionChange(index, "title", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    sx={{ flexGrow: 1, width: "30%" }}
                    inputProps={{ maxLength: 50 }} // Limit the subsection title to 100 characters
                  />
                  <TextField
                    label="Description"
                    value={subsection.description}
                    onChange={(e) =>
                      handleSubsectionChange(index, "description", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    sx={{ flexGrow: 3, width: "60%" }}
                    inputProps={{ maxLength: 100 }} // Limit the subsection description to 300 characters
                  />
                  <IconButton
                    onClick={() => removeSubsection(index)}
                    size="small"
                    sx={{ paddingLeft: 0, marginLeft: 0 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}

          <Button
            variant="contained"
            onClick={handleGenerateNewSection}
            sx={{
              textTransform: "none",
              mt: 1,
            }}
          >
            Generate
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default DraftLeftPanelComponent;
